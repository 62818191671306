<template>
  <div>
    <h5 class="info-text">
      Select Departments
    </h5>
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="md-layout">
          <div
            v-for="item in departments"
            :key="item.name"
            class="md-layout-item md-size-33 md-small-size-100"
          >
            <icon-checkbox
              :checked="selectedItems
                .findIndex(i => i.department_id === item.department_id ) !== -1"
              :icon="item.icon"
              :title="item.name"
              @input="onCheck($event, item)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { IconCheckbox } from '@/components';

export default {
  components: {
    IconCheckbox,
  },
  props: {
    depts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      departments: [],
      selectedItems: [],
    };
  },
  watch: {
    depts(val) {
      this.selectedItems = val;
    },
  },
  mounted() {
    this.getDepartments();
  },
  methods: {
    validate() {
      if (this.selectedItems.length > 0) {
        this.$emit('on-validated', true, {
          departments: this.selectedItems,
        });
        return Promise.resolve(true);
      }
      this.fireError('You must select at least one Department');
      return Promise.resolve(false);
    },
    onCheck(status, item) {
      if (status) {
        this.selectedItems.push(item);
      } else if (this.selectedItems
        .findIndex((i) => i.department_id === item.department_id) !== -1) {
        this.selectedItems.splice(
          this.selectedItems.findIndex((i) => i.department_id === item.department_id),
          1,
        );
      }
    },
    getDepartments() {
      this.request(`${this.$API.DEPARTMENTS}?per_page=1000`, 'get', null, ({ data }) => {
        this.departments = data.data.map((x) => ({
          icon: 'far fa-building',
          department_id: x.department_id,
          name: x.name,
        }));
      });
    },
  },
};
</script>
<style></style>
