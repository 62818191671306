var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-66 md-xsmall-size-80 mx-auto"},[_c('simple-wizard',{attrs:{"prev-route":{name:'Users'}}},[_c('template',{slot:"header"},[_c('h3',{staticClass:"title"},[_vm._v(" New User Form ")]),_c('h5',{staticClass:"category"},[_vm._v(" Complete all the form. ")])]),_c('wizard-tab',{attrs:{"before-change":function () { return _vm.validateStep('step1'); }}},[_c('template',{slot:"label"},[_vm._v(" Info ")]),_c('first-step',{ref:"step1",attrs:{"first-data":{
            image: _vm.wizardModel.image,
            image_url: _vm.wizardModel.image_url,
            firstName: _vm.wizardModel.firstName,
            lastName: _vm.wizardModel.lastName,
            email: _vm.wizardModel.email,
            is_authority: _vm.wizardModel.is_authority,
            is_default_authority: _vm.wizardModel.is_default_authority,
            position: _vm.wizardModel.position,
            signature: _vm.wizardModel.signature,
            is_agent: _vm.wizardModel.is_agent,
            is_teacher: _vm.wizardModel.is_teacher,
            commissions: _vm.wizardModel.commissions,
            agent_groups: _vm.wizardModel.agent_groups,
          }},on:{"on-validated":_vm.onStepValidated}})],2),_c('wizard-tab',{attrs:{"before-change":function () { return _vm.validateStep('step2'); }}},[_c('template',{slot:"label"},[_vm._v(" Department ")]),_c('second-step',{ref:"step2",attrs:{"depts":_vm.wizardModel.departments},on:{"on-validated":_vm.onStepValidated}})],2),_c('wizard-tab',{attrs:{"before-change":function () { return _vm.validateStep('step3'); }}},[_c('template',{slot:"label"},[_vm._v(" Role ")]),_c('third-step',{ref:"step3",attrs:{"roles":_vm.wizardModel.roles},on:{"on-validated":_vm.wizardComplete}})],2)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }