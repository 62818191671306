<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <simple-wizard :prev-route="{name:'Users'}">
        <template slot="header">
          <h3 class="title">
            New User Form
          </h3>
          <h5 class="category">
            Complete all the form.
          </h5>
        </template>

        <wizard-tab :before-change="() => validateStep('step1')">
          <template slot="label">
            Info
          </template>
          <first-step
            ref="step1"
            :first-data="{
              image: wizardModel.image,
              image_url: wizardModel.image_url,
              firstName: wizardModel.firstName,
              lastName: wizardModel.lastName,
              email: wizardModel.email,
              is_authority: wizardModel.is_authority,
              is_default_authority: wizardModel.is_default_authority,
              position: wizardModel.position,
              signature: wizardModel.signature,
              is_agent: wizardModel.is_agent,
              is_teacher: wizardModel.is_teacher,
              commissions: wizardModel.commissions,
              agent_groups: wizardModel.agent_groups,
            }"
            @on-validated="onStepValidated"
          />
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep('step2')">
          <template slot="label">
            Department
          </template>
          <second-step
            ref="step2"
            :depts="wizardModel.departments"
            @on-validated="onStepValidated"
          />
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep('step3')">
          <template slot="label">
            Role
          </template>
          <third-step
            ref="step3"
            :roles="wizardModel.roles"
            @on-validated="wizardComplete"
          />
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-restricted-syntax */
import { SimpleWizard, WizardTab } from '@/components';
import FirstStep from './Form/FirstStep.vue';
import SecondStep from './Form/SecondStep.vue';
import ThirdStep from './Form/ThirdStep.vue';

export default {
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab,
  },
  data() {
    return {
      wizardModel: {
        image: null,
        image_url: null,
        firstName: null,
        password: null,
        is_agent: null,
        is_teacher: null,
        was_agent: null,
        commissions: [],
        lastName: null,
        email: null,
        roles: [],
        departments: [],
        agent_groups: [],
        is_authority: false,
        is_default_authority: false,
        position: null,
        signature: null,
        signature_file: null,
      },
      loading: false,
    };
  },
  mounted() {
    if (this.$route.name === 'EditUser') {
      this.getUser(this.$route.params.id);
    }
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      if (validated) {
        this.wizardModel = { ...this.wizardModel, ...model };
      }
    },
    async wizardComplete(validated, model) {
      this.loading = true;
      this.wizardModel = { ...this.wizardModel, ...model };

      const { id } = this.$route.params;
      const formData = new FormData();
      formData.append('image', this.wizardModel.image);
      formData.append('signature_file', this.wizardModel.signature_file);

      formData.append('firstName', this.wizardModel.firstName);
      formData.append('lastName', this.wizardModel.lastName);
      formData.append('password', this.wizardModel.password);
      formData.append('email', this.wizardModel.email);
      formData.append('roles', JSON.stringify(this.wizardModel.roles));
      formData.append('departments', JSON.stringify(this.wizardModel.departments));

      formData.append('is_agent', this.wizardModel.is_agent);
      formData.append('was_agent', this.wizardModel.was_agent);
      formData.append('commissions', JSON.stringify(this.wizardModel.commissions));
      formData.append('agent_groups', JSON.stringify(this.wizardModel.agent_groups));
      formData.append('is_teacher', this.wizardModel.is_teacher);
      formData.append('is_authority', this.wizardModel.is_authority);
      formData.append('is_default_authority', this.wizardModel.is_default_authority);
      formData.append('position', this.wizardModel.position);

      if (id == null) {
        this.request(`${this.$API.USERS}`, 'post', formData, () => {
          this.fireSuccess('New User has been created!');
          this.onReturn();
        }, () => {
          this.loading = false;
        });
      } else {
        this.request(`${this.$API.USERS}${id}`, 'put', formData, () => {
          this.fireSuccess('User has been updated!');
          this.onReturn();
        }, () => {
          this.loading = false;
        });
      }
    },
    getUser(id) {
      this.loading = true;
      this.request(`${this.$API.USERS}${id}`, 'get', null, ({ data }) => {
        this.wizardModel = {
          image: null,
          image_url: data.image_url,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          roles: data.roles,
          departments: data.departments,
          is_authority: data.is_authority,
          is_default_authority: data.is_default_authority,
          position: data.position,
          signature: data.signature,
          is_agent: data.is_agent,
          is_teacher: data.is_teacher,
          was_agent: data.was_agent,
          commissions: data.commissions,
          agent_groups: data.agent_groups,
        };
        this.loading = false;
      });
    },
    onReturn() {
      this.loading = false;

      this.$router.push({ name: 'Users' });
    },
  },
};
</script>
