<template>
  <div>
    <h5 class="info-text">
      Select Roles
    </h5>
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="md-layout">
          <div
            v-for="item in rolesOptions"
            :key="item.name"
            class="md-layout-item md-size-33 md-small-size-100"
          >
            <icon-checkbox
              :checked="selectedItems.map(x=>x.role_id).includes(item.role_id)"
              :icon="item.icon"
              :title="item.name"
              @input="onCheck($event, item)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { IconCheckbox } from '@/components';

export default {
  components: {
    IconCheckbox,
  },
  props: {
    roles: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      rolesOptions: [],
      selectedItems: [],
    };
  },
  watch: {
    roles(val) {
      this.selectedItems = val;
    },
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    getRoles() {
      this.request(`${this.$API.ROLES}?per_page=1000`, 'get', null, ({ data }) => {
        this.rolesOptions = data.data.map((x) => ({
          role_id: x.role_id,
          name: x.slug_name,
          icon: 'fas fa-user',
        }));
      });
    },
    validate() {
      if (this.selectedItems.length > 0) {
        this.$emit('on-validated', true, {
          roles: this.selectedItems,
        });
        return Promise.resolve(true);
      }

      this.fireError('You must select at least one Role');
      return Promise.resolve(false);
    },
    onCheck(status, item) {
      const selIds = this.selectedItems.map((x) => x.role_id);
      if (status) {
        this.selectedItems.push(item);
      } else if (selIds.includes(item.role_id)) {
        this.selectedItems.splice(
          selIds.findIndex((i) => i === item.role_id),
          1,
        );
      }
    },
  },
};
</script>
<style></style>
