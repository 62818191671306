<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div>
        <h5 class="info-text">
          Let's start with the basic information
        </h5>
        <div class="md-layout">
          <div class="md-layout-item md-size-40 md-small-size-100">
            <form-uploader
              class="imgSelector"
              image-type
              @input="imageSelected"
              @error="fireError"
            >
              <div class="picture-container">
                <div class="picture">
                  <img
                    :src="image_url || avatar"
                    class="picture-src"
                  >
                </div>
                <h6 class="description mt-3 mb-0 pb-0">
                  Choose Picture
                </h6>
                <small>The image must be JPG or PNG</small> <br>
                <small>The dimensions must be 300x300</small> <br>
                <small>The size cannot be higher than 5mb</small>
              </div>
            </form-uploader>
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100">
            <form-text
              v-model="firstName"
              name="firstName"
              rules="required"
              icon="face"
              label="Given Name"
            />

            <form-text
              v-model="lastName"
              name="lastName"
              rules="required"
              icon="record_voice_over"
              label="Surname"
            />

            <form-text
              v-model="email"
              name="email"
              rules="required"
              icon="email"
              label="Email"
            />

            <md-button
              v-if="!changePw && $route.name !== 'NewUser'"
              @click="changePw = true"
            >
              Change Password
            </md-button>
            <form-text
              v-if="changePw"
              v-model="password"
              name="newpass"
              rules="required"
              icon="fingerprint"
              label="New Password"
              password
            />

            <div class="d-flex g-4 mb-3">
              <md-switch
                v-model="is_authority"
                class="mb-0"
              >
                Is Authority
              </md-switch>

              <md-switch
                v-if="is_authority"
                v-model="is_default_authority"
                class="mb-0"
              >
                Is default Authority <small>(For automatic letters)</small>
              </md-switch>
            </div>

            <md-switch
              v-model="is_agent"
              class="mb-3"
            >
              Is Agent
            </md-switch>

            <md-switch
              v-model="is_teacher"
              class="mb-3"
            >
              Is Teacher
            </md-switch>

            <div
              v-if="is_authority"
              class="md-layout mb-4"
            >
              <div class="md-layout-item md-size-100">
                <h5>Authority data</h5>

                <FormText
                  v-model="position"
                  label="Position / <small>for example: Head of school</small>"
                  icon="work"
                />
                <div class="d-flex">
                  <form-uploader
                    v-model="signature_file"
                  >
                    <md-button class="md-success">
                      Upload Signature Photo
                    </md-button>
                  </form-uploader>

                  <md-button
                    v-if="signature"
                    class="md-primary"
                    @click="openSignature(signature)"
                  >
                    Preview Signature Photo
                  </md-button>
                </div>
                <div v-if="signature_file">
                  <b class="text-success">{{ signature_file.name }}</b>
                </div>
              </div>
            </div>

            <div
              v-if="is_agent"
              class="md-layout"
            >
              <!-- Agent Groups -->
              <div class="md-layout-item md-size-100 mb-4">
                <md-button
                  class="pull-right md-primary md-simple md-sm m-0"
                  @click="adding_group = true"
                >
                  <md-icon>add</md-icon> add group
                </md-button>

                <h5 class="mt-1 mb-3">
                  Agent Groups
                </h5>

                <FormSelect
                  v-if="adding_group"
                  v-model="groupToAdd"
                  :options="available_groups"
                  label="Add Agent Group"
                >
                  <md-button
                    slot="button"
                    class="m-0 md-info"
                    @click="addGroup"
                  >
                    Add
                  </md-button>
                </FormSelect>

                <small v-if="!agent_groups || !agent_groups.length">Agent has no groups yet</small>
                <ul
                  v-else
                  class="agent_groups"
                >
                  <li
                    v-for="(g,index) of agent_groups"
                    :key="g.agent_group_id"
                  >
                    <button
                      class="md-button md-simple md-icon md-danger"
                      @click="agent_groups.splice(index,1)"
                    >
                      <md-icon>close</md-icon>
                      <md-tooltip>Remove</md-tooltip>
                    </button>

                    <span>
                      {{ g.name }}
                    </span>

                    <FormCheckbox
                      v-model="g.is_boss"
                      @change="bossAttrChanged($event, index)"
                    >
                      Is Group Admin
                    </FormCheckbox>
                  </li>
                </ul>
              </div>

              <!-- Comissions -->
              <div class="md-layout-item md-size-100 pr-0">
                <md-button
                  class="pull-right md-primary md-simple md-sm m-0"
                  @click="addComissions"
                >
                  <md-icon>add</md-icon> more commissions
                </md-button>
                <h5 class="mt-1 mb-3 pull-left">
                  Comissions
                </h5>
              </div>
              <div class="md-layout-item md-size-100">
                <div
                  v-for="(c,index) of commissions"
                  :key="c.key"
                  class="md-layout"
                >
                  <div class="md-layout-item md-size-10 pl-0">
                    <md-button
                      v-if="commissions.length > 1"
                      class="md-simple md-danger md-just-icon mt-2"
                      @click="removeComission(index)"
                    >
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                  <div class="md-layout-item md-size-45 pl-0">
                    <form-text
                      v-model="c.description"
                      icon="description"
                      label="Description"
                      rules="required"
                      :disabled="c.agent_commission_id"
                    />
                  </div>
                  <div class="md-layout-item pr-0">
                    <form-number
                      v-model="c.percentage"
                      icon="donut_large"
                      label="Commision Percentage"
                      rules="required"
                      :disabled="c.agent_commission_id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <image-cropper
        v-if="showCropper"
        :img="image_url"
        :img-type="image_type"
        @imageCropped="imageCropped"
        @close="showCropper = false"
      />
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import {
  FormNumber,
  FormText,
  FormSelect,
  FormUploader,
  FormCheckbox,
} from '@/components/Inputs';
import ImageCropper from '@/components/ImageCropper.vue';

extend('required', required);
extend('email', email);

export default {
  components: {
    FormText,
    FormSelect,
    FormUploader,
    FormNumber,
    ImageCropper,
    FormCheckbox,
  },
  props: {
    firstData: {
      type: Object,
      default() {
        return {
          image: null,
          firstName: null,
          lastName: null,
          email: null,
          is_agent: null,
          was_agent: null,
          password: null,
          commissions: null,
          agent_groups: [],
          is_authority: false,
          is_default_authority: false,
          position: null,
          signature_file: null,
          signature: null,
        };
      },
    },
  },
  data() {
    return {
      image: null,
      image_url: null,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      avatar: '/img/default-avatar.png',
      is_authority: false,
      is_default_authority: false,
      position: null,
      signature_file: null,
      signature: null,
      is_agent: null,
      is_teacher: null,
      changePw: false,
      commissions: [],
      agent_groups: [],
      adding_group: false,
      groupToAdd: null,
      showCropper: false,
      image_type: null,
    };
  },
  computed: {
    available_groups() {
      return this.$store.getters['agentGroups/options'];
    },
  },
  watch: {
    firstData(val) {
      this.image = val.image;
      this.image_url = val.image_url;
      this.firstName = val.firstName;
      this.lastName = val.lastName;
      this.password = val.password;
      this.email = val.email;
      this.is_authority = val.is_authority;
      this.is_default_authority = val.is_default_authority;
      this.position = val.position;
      this.signature = val.signature;
      this.is_agent = val.is_agent;
      this.is_teacher = val.is_teacher;
      this.commissions = val.commissions ? [...val.commissions] : [{
        agent_commission_id: null,
        description: null,
        percentage: 0,
        key: Math.random() * Date.now(),
      }];
      this.agent_groups = val.agent_groups ? [...val.agent_groups] : [];
    },
  },
  mounted() {
    this.getGroups();
  },
  methods: {
    imageSelected(imageFile) {
      const imageURL = URL.createObjectURL(imageFile);
      this.image = imageFile;
      this.image_url = imageURL;
      this.showCropper = true;
      this.imageType = imageFile.type;
      this.$forceUpdate();
    },
    imageCropped(blob) {
      const imageURL = URL.createObjectURL(blob);

      // Convert blob to file
      const file = blob;
      file.lastModifiedDate = new Date();
      file.name = 'image';

      this.image = file;
      this.image_url = imageURL;
    },
    bossAttrChanged($event, index) {
      this.agent_groups[index].is_boss = $event;
    },
    validate() {
      if (this.is_authority && !this.position) {
        this.fireError('Position attribute is required for authorities');
        return false;
      }
      if (this.is_authority && !(this.signature || this.signature_file)) {
        this.fireError('Please upload signature for authority');
        return false;
      }
      if (this.is_agent && !this.agent_groups.length) {
        this.fireError('Each Agent must be at least in one group');
        return false;
      }
      return this.$refs.form.validate().then((res) => {
        this.$emit('on-validated', res, {
          image: this.image,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          is_agent: this.is_agent,
          is_teacher: this.is_teacher,
          password: this.password,
          commissions: this.commissions,
          agent_groups: this.agent_groups,
          is_authority: this.is_authority,
          is_default_authority: this.is_default_authority,
          position: this.position,
          signature_file: this.signature_file,
        });
        return res;
      });
    },
    addComissions() {
      this.commissions.push({
        agent_commission_id: null,
        description: null,
        percentage: 0,
        key: Math.random() * Date.now(),
      });
    },
    addGroup() {
      if (this.agent_groups.map((x) => x.id).includes(this.groupToAdd)) {
        this.fireError('Group already added');
        return;
      }

      const group = this.available_groups.find((x) => x.id === this.groupToAdd);

      this.agent_groups.push({
        is_boss: false,
        ...group,
      });
      this.adding_group = false;
    },
    removeComission(index) {
      this.commissions.splice(index, 1);
    },
    getGroups() {
      this.$store.dispatch('agentGroups/getOptions', {});
    },
    openSignature(signature) {
      window.open(signature, '_blank');
    },
  },
};
</script>
<style scoped lang="scss">
.picture-container {
    line-height: 1em;
}
.agent_groups {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;

    .FormCheckbox {
      margin-left: 1em;
      padding: 0;
    }

  }

}
</style>
